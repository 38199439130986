<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    icon?: string | string[];
    iconSize?: string | string[];
    appendIcon?: string | string[];
    pushAppendIcon?: boolean;
    disabled?: boolean;
    loading?: boolean;
    type?: "submit" | "button" | "reset" | "anchor";
    btnStyle?: "primary" | "secondary" | "tertiary" | "white" | "accentDark";
    btnHoverStyle?: "primary" | "secondary" | "tertiary" | "white" | "red" | "accentDark" | "";
  }>(),
  {
    icon: "",
    iconSize: "lg",
    appendIcon: "",
    pushAppendIcon: false,
    disabled: false,
    loading: false,
    type: "anchor",
    btnStyle: "primary",
    btnHoverStyle: "",
  }
);

const component = computed(() => (props.type === "anchor" ? resolveComponent("NuxtLink") : "button"));

const btnStyleClasses = {
  primary: "bg-primary text-white transition",
  accentDark: "bg-accentDark text-white transition",
  secondary: "bg-secondary text-accentDark transition",
  tertiary: "bg-tertiary text-primary transition",
  white: "bg-white text-primary transition",
};

const btnStyleHoverClasses = {
  primary: "hover:bg-primary hover:text-white transition",
  accentDark: "hover:bg-accentDark hover:text-white transition",
  secondary: "hover:bg-secondary hover:text-black transition",
  tertiary: "hover:bg-tertiary hover:text-black transition",
  white: "hover:bg-white hover:text-primary transition",
  red: "hover:bg-red-600 hover:text-white transition",
};
</script>

<template>
  <component
    :is="component"
    class="group relative flex cursor-pointer items-center justify-center space-x-2 text-base"
    :disabled="disabled"
    :type="type"
    :class="[
      disabled && 'pointer-events-none cursor-not-allowed opacity-70',
      btnStyleClasses[btnStyle],
      btnHoverStyle ? btnStyleHoverClasses[btnHoverStyle] : btnStyleHoverClasses['secondary'],
      icon && !$slots.default ? 'relative size-14 rounded-full p-0' : 'w-fit rounded-full px-8 py-5 text-sm',
    ]"
  >
    <font-awesome-icon
      v-if="loading"
      class="animate-spin group-disabled:opacity-70"
      :class="[!$slots.default && 'absolute inset-0 m-auto']"
      icon="fa-solid fa-spinner"
      spin-pulse
      :size="iconSize"
    />
    <font-awesome-icon
      v-else-if="icon"
      :icon="icon"
      class="group-disabled:opacity-70"
      :class="[icon && !$slots.default && 'absolute inset-0 m-auto']"
      :size="iconSize"
    />
    <span v-if="$slots.default" class="font-semibold uppercase group-disabled:opacity-70">
      <slot></slot>
    </span>
    <font-awesome-icon
      v-if="appendIcon"
      :icon="appendIcon"
      :size="iconSize"
      class="group-disabled:opacity-70"
      :style="[pushAppendIcon && 'margin-left: auto']"
    />
  </component>
</template>
